/**
 * Invoice module for Suncast Portal
 * @module InvoiceStore
 * @author Mithun Paulose (Fresche Solutions)
 * @date June 2020
 */

import { getField, updateField } from "vuex-map-fields";
import portalapi from "./portalapi";

const INITIAL_STATE = {
    pageLimit: 25,
    invoiceList: null,
    openInvoices: null,
    invoiceDetails: null,
    selectedInvoices: [],
    administrationFee: null,
    fromDate: null,
    toDate: null,
    openInvoicesOnly: true,
    totals: {
        pastDueTotals: 0.00,
        thisMonthDueTotals: 0.00,
        nextMonthDueTotals: 0.00,
        totalAR: 0.00,
    },
    invoices: {
        pastDueInvoices: [],
        thisMonthInvoices: [],
        nextMonthInvoices: [],
        allInvoices: [],
    },
};

const state = {...INITIAL_STATE };


const getters = {
    getField,
    getInvoiceList: (state) => {
        return state.invoiceList;
    },
    getInvoiceDetails: (state) => {
        return state.invoiceDetails;
    },
    getSelectedInvoices: (state) => {
        return state.selectedInvoices;
    },
    getAdministrationFee: (state) => {
        return state.administrationFee;
    },
    getFromDate: (state) => {
        return state.fromDate;
    },
    getToDate: (state) => {
        return state.toDate;
    },
    getOpenInvoicesOnly: (state) => {
        return state.openInvoicesOnly;
    },
    getTotals: (state) => {
        return state.totals;
    },
    getInvoices: (state) => {
        return state.invoices;
    },
    getOpenInvoices: (state) => {
        return state.openInvoices;
    },
    getPageLimit: (state) => {
        return state.pageLimit;
    },
};

const mutations = {
    updateField,
    resetInvoiceData(state) {
        let newState = {...INITIAL_STATE };
        state = Object.assign(state, newState);
    },
    setInvoiceList(state, payload) {
        state.invoiceList = payload;
    },
    setInvoiceDetails(state, payload) {
        state.invoiceDetails = payload;
    },
    setSelectedInvoices(state, payload) {
        state.selectedInvoices = payload;
    },
    setAdministrationFee(state, payload) {
        state.administrationFee = payload;
    },
    setFromDate(state, payload) {
        state.fromDate = payload;
    },
    setToDate(state, payload) {
        state.toDate = payload;
    },
    setOpenInvociesOnly(state, payload) {
        state.openInvoicesOnly = payload;
    },
    setTotals(state, payload) {
        state.totals = payload;
    },
    setInvoices(state, payload) {
        state.invoices = payload;
    },
    setOpenInvoices(state, payload) {
        state.openInvoices = payload;
    },
    setPageLimit(state, payload) {
        state.pageLimit = payload;
    },
};

const actions = {
    async calculateTotals(context) {
        context.dispatch("clearCategories");
        if (state.openInvoices) {
            for (let i = 0; i < state.openInvoices.invoices.length; i++) {
                let invoice = state.openInvoices.invoices[i];
                let dt2 = new Date(invoice.dueDate);
                let dt1 = new Date();
                let timeDiff = dt2.getTime() - dt1.getTime();
                let dateDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

                if (dateDiff < 0) {
                    state.totals.pastDueTotals = parseFloat(state.totals.pastDueTotals) + parseFloat(invoice.total);
                    state.invoices.pastDueInvoices.push(invoice.invoiceNumber);
                } else if (dateDiff >= 0 && dateDiff < 31) {
                    state.totals.thisMonthDueTotals = parseFloat(state.totals.thisMonthDueTotals) + parseFloat(invoice.total);
                    state.invoices.thisMonthInvoices.push(invoice.invoiceNumber);
                } else if (dateDiff >= 31 && dateDiff <= 60) {
                    state.totals.nextMonthDueTotals = parseFloat(state.totals.nextMonthDueTotals) + parseFloat(invoice.total);
                    state.invoices.nextMonthInvoices.push(invoice.invoiceNumber);
                }

                state.totals.totalAR = parseFloat(state.totals.totalAR) + parseFloat(invoice.total);
                state.invoices.allInvoices.push(invoice.invoiceNumber);
            }
        }
    },
    clearTotals() {
        state.totals = {
            pastDueTotals: 0.00,
            thisMonthDueTotals: 0.00,
            nextMonthDueTotals: 0.00,
            totalAR: 0.00,
        };
    },
    //Clear catergorised totals and invoices list
    clearCategories() {
        state.totals.pastDueTotals = 0.00;
        state.totals.thisMonthDueTotals = 0.00;
        state.totals.nextMonthDueTotals = 0.00;
        state.totals.totalAR = 0.00;
        state.invoices.pastDueInvoices = [];
        state.invoices.thisMonthInvoices = [];
        state.invoices.nextMonthInvoices = [];
        state.invoices.allInvoices = [];
    },
    /**
     * get administration fee(%)
     */
    async fetchAdministrationFee({ commit }) {
        //var response = awawit portalapi.moduleinvoices.getAdministrationFee();
        commit("setAdministrationFee", 3);
    },
    /**
     * update selected Invoices list
     */
    async updateSelectedInvoices({ commit }, data) {
        commit("setSelectedInvoices", data);
    },
    /**
     * invoice List History
     */
    async fetchInvoiceList(context, data) {

        // Require filters
        if (!data) {
            console.error('Error: fetchInvoiceList() was called without filters. No invoices will be retrieved.');
            return;
        }

        var response = await portalapi.moduleInvoices.getInvoices(data);
        context.commit("setInvoiceList", response.data);
    },
    /**
     * Open Invoices List
     */
    async fetchOpenInvoiceList(context) {
        var response = await portalapi.moduleInvoices.getInvoices({ status: 'Open' });
        context.commit("setOpenInvoices", response.data);
        context.dispatch("calculateTotals");
    },
    /**
     * Invoice Details
     */
    async fetchInvoiceDetails({ commit }, data) {
        var response = await portalapi.moduleInvoices.getInvoices({ invoiceNumber: data });
        commit("setInvoiceDetails", response.data);
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};