/**
 * Order Store
 * @module OrderStore
 * @author Mithun Paulose (Fresche Solutions)
 * @date June 2020
 */

import { getField, updateField } from "vuex-map-fields";
import portalapi from "./portalapi";

const INITIAL_STATE = {
    orderList: null,
    orderDetails: null,
    fromDate: null,
    toDate: null,
    pageLimit: 25,
};

const state = {...INITIAL_STATE};

const getters = {
    getOrderList: (state) => {
        return state.orderList;
    },

    getOrderDetails: (state) => {
        return state.orderDetails;
    },

    getFromDate: (state) => {
        return state.fromDate;
    },

    getToDate: (state) => {
        return state.toDate;
    },

    getPageLimit(state) {
        return state.pageLimit;
    },
};

const mutations = {
    resetOrderHistoryData(state) {
        let newState = {...INITIAL_STATE};
        state = Object.assign(state, newState);
    },

    setOrderList(state, payload) {
        state.orderList = payload;
    },

    setOrderDetails(state, payload) {
        state.orderDetails = payload;
    },

    setFromDate(state, payload) {
        state.fromDate = payload;
    },

    setToDate(state, payload) {
        state.toDate = payload;
    },

    setPageLimit(state, payload) {
        state.pageLimit = payload;
    },
};

const actions = {
    /**
     * Order List History
     */
    async fetchOrderList({ commit }, data) {
        var response = await portalapi.moduleOrders.getOrderList(data);
        commit("setOrderList", response.data);
    },

    /**
     * Order Details
     */
    async fetchOrderDetails({ commit }, orderNumber) {
        var response = await portalapi.moduleOrders.getOrderDetails(orderNumber);
        commit("setOrderDetails", response.data);
    },

    /**
     * Delete Order
     *
     * @param int orderNumber
     */
    async deleteOrder({ commit }, orderNumber) {
        let response = await portalapi.moduleOrders.deleteOrder(orderNumber).catch(err => false);
        return response.data;
    },

    /**
     * Delete Order Item(s)
     *
     * @param int orderNumber
     * @param array items
     */
    async deleteOrderItems({ commit }, data) {
        let response = await portalapi.moduleOrders.deleteOrderItems(data.orderNumber, data.itemList).catch(err => false);
        return response.data;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
