/**
 * Cart Vuex module for Suncast Portal Application
 * Controls the data and actions upon the Cart
 * @module Cart
 * @see
 * @author Scott Gingerysty (Fresche Solutions)
 * @date May 2020
 */

import portalapi from "../../../../services/portalapi";
import { getField, updateField } from "vuex-map-fields";

// Valid cart types
const CART_TYPE_ACTIVE = 'A';
const CART_TYPE_SAVED = 'S';
const CART_TYPE_TEMP = 'T';

const state = {
    activeCart: null,
    savedCarts: [],
    activeCarts: [],
    pageLimit: 10,
};

const getters = {
    getField, // for vuex-map-fields

    getPageLimit(state) {
        return state.pageLimit;
    },
};

const mutations = {

    updateField, // for vuex-map-fields

    setPageLimit(state, payload) {
        state.pageLimit = payload;
    },

    setActiveCart(state, payload) {
        state.activeCart = payload;
    },

    setSavedCarts(state, payload) {
        state.savedCarts = payload;
    },

    setActiveCarts(state, payload) {
        state.activeCarts = payload;
    },

    /**
     * Update the active cart and recalculate its summary values.
     *
     * Summary values in activeCart:
     *      count        : Number of line items
     *      subtotal     : Total extended prices of line items
     *      tax          : Total tax on subtotal
     *      shipping     : Total shipping on items
     *      total        : subtotal + tax + shipping
     */
    updateActiveCart(state, activeCart) {

        // if active cart is null, use the current cart
        // this is used for updating the taxes based on updates to userdata
        if (!activeCart) {
            activeCart = state.activeCart;
        }

        if (!activeCart) return;

        if (!activeCart.lineItems) {
            activeCart.lineItems = [];
        }

        // Currently the API returns total as a total of items extended prices. IE: a subtotal.
        // We'll change this once the the API deals with everything. For now, we'll recalculate that value
        // and as as subtotal. Then overwrite the total with the actual total.
        activeCart.count = 0;
        activeCart.subtotal = 0;
        for (let i = 0; i < activeCart.lineItems.length; i++) {
            activeCart.subtotal = activeCart.subtotal + parseFloat(activeCart.lineItems[i].extendedPrice);
            activeCart.count = activeCart.count + parseInt(activeCart.lineItems[i].quantity);
        }

        // Shipping
        activeCart.shippingCost = 0; // TODO: Get from an API call

        // Taxes - get rate from user store
        let taxes = vm.$store.state.User.userData.tax || {};

        // Just be sure each property has a value or we'll get NaNs all over
        if (!taxes.rate) taxes.rate = 0;
        if (!taxes.city_tax_rate) taxes.city_tax_rate = 0;
        if (!taxes.county_tax_rate) taxes.county_tax_rate = 0;
        if (!taxes.special_tax_rate) taxes.special_tax_rate = 0;
        if (!taxes.state_tax_rate) taxes.state_tax_rate = 0;

        activeCart.tax = parseFloat(activeCart.subtotal * taxes.rate);
        activeCart.cityTax = parseFloat(activeCart.subtotal * taxes.city_tax_rate);
        activeCart.countyTax = parseFloat(activeCart.subtotal * taxes.county_tax_rate);
        activeCart.specialTax = parseFloat(activeCart.subtotal * taxes.special_tax_rate);
        activeCart.stateTax = parseFloat(activeCart.subtotal * taxes.state_tax_rate);

        activeCart.taxRate = taxes.rate * 100;
        activeCart.cityTaxRate = taxes.city_tax_rate * 100;
        activeCart.countyTaxRate = taxes.county_tax_rate * 100;
        activeCart.specialTaxRate = taxes.special_tax_rate * 100;
        activeCart.stateTaxRate = taxes.state_tax_rate * 100;

        // Total
        activeCart.total = parseFloat(activeCart.subtotal) + parseFloat(activeCart.tax) + parseFloat(activeCart.shippingCost);

        // Format each
        activeCart.taxRate = activeCart.taxRate.toFixed(3);
        activeCart.cityTaxRate = activeCart.cityTaxRate.toFixed(3);
        activeCart.countyTaxRate = activeCart.countyTaxRate.toFixed(3);
        activeCart.specialTaxRate = activeCart.specialTaxRate.toFixed(3);
        activeCart.stateTaxRate = activeCart.stateTaxRate.toFixed(3);

        activeCart.subtotal = activeCart.subtotal.toFixed(2);
        activeCart.tax = activeCart.tax.toFixed(2);
        activeCart.cityTax = activeCart.cityTax.toFixed(2);
        activeCart.countyTax = activeCart.countyTax.toFixed(2);
        activeCart.specialTax = activeCart.specialTax.toFixed(2);
        activeCart.stateTax = activeCart.stateTax.toFixed(2);
        activeCart.shippingCost = activeCart.shippingCost.toFixed(2);
        activeCart.total = activeCart.total.toFixed(2);

        // Update the cart
        state.activeCart = activeCart;
    },
};

const actions = {

    // Save the list's page limit
    setPageLimit({ commit }, pageLimit) {
        commit("setPageLimit", pageLimit);
    },

    // Get a list of saved carts for this user & customer
    async getSavedCarts({ commit }, data) {

        // Get a list of this customer's saved carts for this user
        var response = await portalapi.moduleShopping.getCustomerCarts({
            userId: data.userId,
            customerId: data.currentCustomer,
            type: CART_TYPE_SAVED
        });

        if (response.data.carts) {
            commit("setSavedCarts", response.data.carts);
            return response.data.carts;
        }

        return null;
    },

    // Get a list of all active carts for this user & customer
    async getActiveCarts({ commit }, data) {

        // Get a list of all active carts for this customer and user
        var response = await portalapi.moduleShopping.getCustomerCarts({
            userId: data.userId,
            customerId: data.currentCustomer,
            type: CART_TYPE_ACTIVE
        });

        // Save the carts
        if (response.data.carts) {
            commit("setActiveCarts", response.data.carts);
            return response.data.carts;
        }

        return null;
    },

    // Get the active cart
    async getActiveCart({ commit }, force = false) {

        let activeCart = this.state.Cart.activeCart;

        // if we already have an active cart
        // and we are not forcing an update
        if (activeCart && !force) {
            // return the current cart
            return activeCart;
        }

        // get the cart from DB
        var response = await portalapi.moduleShopping.getActiveCart();

        // if an error is found
        if (!response.data.cart) {
            // return the current cart
            return activeCart;
        }

        let newActiveCart = response.data.cart;

        // if we have an existing cart - get the insufficient stock flags
        if (activeCart) {
            // loop through new cart line items
            for (let i = 0; i < newActiveCart.lineItems.length; i++) {
                let newItem = newActiveCart.lineItems[i];

                let oldItem = activeCart.lineItems.find((itm) => itm.id == newItem.id);
                if (oldItem) {
                    // get the stock flag;                    
                    newItem.acceptedInsufficientStock = oldItem.acceptedInsufficientStock;                    
                }

                // update the item in the new cart
                newActiveCart.lineItems[i] = newItem;
            }
        }

        // Update the cart and its summary values
        commit("updateActiveCart", newActiveCart);

        return newActiveCart;
    },

    // Clear & update the cart and its summary values
    async clearActiveCart({ commit }) {
        this.state.Cart.activeCart = null;
    },

    // Save the currently active cart
    // For param "type", Valid values for type are: 'S' (Saved) and 'A' (Active). Constants declared above.
    async saveActiveCart({ commit }, cart, type = CART_TYPE_SAVED) {

        let response = await portalapi.moduleShopping.saveActiveCart(cart.name, type);

        return (response.data.success ? true : response.data.errors);
    },

    // Save the currently active cart
    async switchActiveCart({ commit }, cartId) {

        let response = await portalapi.moduleShopping.switchActiveCart(cartId);

        return (response.data.success ? true : response.data.errors);
    },

    // Delete a cart
    async deleteCart({ commit }, cartId) {
        let response = await portalapi.moduleShopping.deleteCart(cartId);

        return (response.data.success ? true : response.data.errors);
    },


    // Update a line item's quantity
    async updateCartItemQuantity({ commit }, lineItem) {

        // Get the active cart, fetching if we don't have it yet
        let activeCart = this.state.Cart.activeCart;
        if (activeCart == null) {
            activeCart = await context.dispatch('getActiveCart');
        }

        // Send the request to update the cart item's quantity
        let response = await portalapi.moduleShopping.updateCartItemQuantity(lineItem);
        if (!response.data.success) {
            return response.data.errors;
        }

        // Check that we got the correct quantity in the response in case it didn't go through. If we didn't,
        // display an error and don't update anything in the cart.
        if (lineItem.quantity != parseInt(response.data.lineItem.quantity)) {
            return {'GLOBAL': 'Quantity not updated. Please try again.'};
        }

        let updatedLineItem = response.data.lineItem;

        // Get the index of the line item in the active cart that we just updated
        let index = activeCart.lineItems.findIndex((curItem) => curItem.id == updatedLineItem.id);

        // Update the line item in the active cart
        activeCart.lineItems[index].quantity = updatedLineItem.quantity;
        activeCart.lineItems[index].extendedPrice = updatedLineItem.extendedPrice;

        // Update the cart and its summary values
        commit("updateActiveCart", activeCart);

        return true;
    },


    // Renove any line items with a quantity of zero
    async removeZeroQuantityItems({ dispatch }) {

        // Get the active cart, fetching if we don't have it yet
        let activeCart = this.state.Cart.activeCart;
        if (activeCart == null) {
            activeCart = await dispatch('getActiveCart');
        }

        // Get items with zero quantities and remove each one individually
        let lineItemsToRemove = activeCart.lineItems.filter(item => parseInt(item.quantity) == 0);
        for (let i = 0; i < lineItemsToRemove.length; i++) {
            await dispatch('removeCartItem', lineItemsToRemove[i]);
        }
    },


    // Renove a line item
    async removeCartItem({ commit, dispatch }, lineItem) {

        // Get the active cart, fetching if we don't have it yet
        let activeCart = this.state.Cart.activeCart;
        if (activeCart == null) {
            activeCart = await dispatch('getActiveCart');
        }

        // Send the request to remove the cart item
        var response = await portalapi.moduleShopping.removeCartItem(lineItem.id);
        if (!response.data.success) {
            return response.data.errors;
        }

        // Successful - remove the item from the active cart & update the cart's count and total
        activeCart.lineItems = activeCart.lineItems.filter((item) => item.id != lineItem.id);

        // Update the cart and its summary values
        commit("updateActiveCart", activeCart);

        return true;
    },


    // Add to cart
    async addToCart(context, product) {

        // Send the request to add the cart item
        let response = await portalapi.moduleShopping.addCartItem({ part: product.part, quantity: product.quantity, price: product.price });
        if (!response.data.success) {
            return response.data.errors;
        }

        // Successful - Preformat some values
        let lineItem = response.data.lineItem;
        lineItem.extendedPrice = parseFloat(lineItem.extendedPrice).toFixed(2);
        lineItem.price = parseFloat(lineItem.price).toFixed(2);

        // If we don't have an active cart, fetch it. It will have the item we just added. Otherwise, just
        // add the item to the cart we have.
        let activeCart = this.state.Cart.activeCart;
        if (activeCart == null) {
            activeCart = await context.dispatch('getActiveCart');
        }
        // We have the active cart - Add the item.
        else {
            activeCart.lineItems.push(lineItem);
        }

        // Update the cart and its summary values
        context.commit("updateActiveCart", activeCart);

        return true;
    },

    // Construct the invalid quantity message based on pack size
    // @params object params
    //      : packSize
    //      : quantityAvailable
    //      : allowInsufficientStockOrdering - Optional. Default = false
    constructInvalidQuantityMessage({ commit }, params) {

        let allowOverOrdering = (params.allowInsufficientStockOrdering ? params.allowInsufficientStockOrdering : false);
        let orderMultiple = (typeof params.packSize == 'string' ? parseInt(params.packSize) : params.packSize);
        let quantityAvailable = (typeof params.quantityAvailable == 'string' ? parseInt(params.quantityAvailable) : params.quantityAvailable);

        // Enforce within limits
        if (!allowOverOrdering) {

            // Not enough available
            if (quantityAvailable < orderMultiple) {
                return 'Cannot order this item';
            }

            // Only enough available for 1 pack size
            if (quantityAvailable < (2 * orderMultiple)) {
                return 'Can only order ' + orderMultiple;
            }

            // Any orderMultiple within range of availability (GCD)
            let max = quantityAvailable - (quantityAvailable % orderMultiple);

            return 'Must be between ' + orderMultiple + ' and ' + max // Range
                +
                (orderMultiple != 1 ? ' and a multiple of ' + orderMultiple : ''); // Multiple (Pack size), if > 1
        }

        // Can order more than available - Specify minimum & Multiple (Pack size), if > 1
        return 'Must be at least ' + orderMultiple + (orderMultiple != 1 ? ' and a multiple of ' + orderMultiple : '');
    },
};

export default {
    namespaced: "true",
    state,
    getters,
    mutations,
    actions
};
