/**
 * Product Vuex module for Suncast Portal Application
 * Controls the data and actions for Suncast Products
 * @module Product
 * @see
 * @author Scott Gingerysty (Fresche Solutions)
 * @date May 2020
 */

import portalapi from "../../../../services/portalapi";
import { getField, updateField } from "vuex-map-fields";

const state = {
    categories: null,
    products: null,
    productDetail: null,
    pageLimit: 25,
};

const getters = {
    getField, // for vuex-map-fields,
    getProductDetail(st) {
        return st.productDetail;
    },
    getProducCategories(st) {
        return st.categories;
    },
    getProducts(st) {
        return st.products;
    },
    getPageLimit(st) {
        return st.pageLimit;
    },
};

const mutations = {
    updateField, // for vuex-map-fields
    setProductCategories(st, payload) {
        st.categories = payload;
    },
    setProducts(st, payload) {
        st.products = payload;
    },
    setProductDetail(st, payload) {
        st.productDetail = payload;

        // if we have category data, fetch the details for the product
        if (st.categories) {
            for (let category of st.categories) {
                for (let sub of category.sub) {
                    if (
                        st.productDetail.line == category.id &&
                        st.productDetail.group == sub.id
                    ) {
                        // add category information to the product
                        st.productDetail.category = category.name;
                        st.productDetail.categoryId = category.id;
                        st.productDetail.subCategory = sub.name;
                        st.productDetail.subCategoryId = sub.id;
                    }
                }
            }
        }

        // get the price from the product list and not the product detail
        let currentProduct = st.products.find(
            (prod, index) => prod.part == st.productDetail.part
        );
        if (currentProduct) {
            st.productDetail.price = currentProduct.price;
        }
    },
    setPageLimit(st, payload) {
        st.pageLimit = payload;
    },
};

const actions = {

    // get product detail
    async fetchProductDetail({ commit }, productId) {

        var response = await portalapi.moduleShopping.getProductDetail(
            productId
        );

        // initialize quantity and id properties so that we can watch it when its added to cart
        if (!response.data.product.packSize) {
            response.data.product.packSize = 1;
        }
        response.data.product.quantity = response.data.product.packSize;
        response.data.product.id = "";

        commit("setProductDetail", response.data.product);
    },

    // get product categories
    async fetchProductCategories({ commit }, estimatedShipDate) {

        // always get products before categories, as categories depend on product list, returning errors on fail.
        var prodResponse = await portalapi.moduleShopping.getProducts({ 'shipDate': estimatedShipDate });
        if (!prodResponse.data.success && prodResponse.data.errors) {
            return prodResponse.data.errors;
        }

        // get categories, returning errors on fail.
        var catResponse = await portalapi.moduleShopping.getProductCategories();
        if (!catResponse.data.success && catResponse.data.errors) {
            return catResponse.data.errors;
        }

        // set visibility (true|false) for categories and subcategories depending on available products
        if (catResponse.data.success) {

            for (let category of catResponse.data.categories) {
                category.visible = false;
                // loop through sub categories
                for (let sub of category.sub) {

                    sub.visible = false;

                    // loop through product list
                    if (prodResponse.data.success) {

                        for (let product of prodResponse.data.products) {
                            // find the product in this subcategory
                            // set found to true for this and the parent category
                            if (
                                product.line == category.id &&
                                product.group == sub.id
                            ) {
                                sub.visible = true;
                                category.visible = true;

                                // add category information to the product
                                product.category = category.name;
                                product.categoryId = category.id;
                                product.subCategory = sub.name;
                                product.subCategoryId = sub.id;
                            }

                            // initialize quantity and id properties so that we can watch it when its added to cart
                            if (!product.packSize) {
                                product.packSize = 1;
                            }
                            product.quantity = product.packSize;
                            product.id = "";
                        }
                    }
                }
            }
        }

        // commit to the store with new visibility properties
        if (catResponse.data.success) {
            commit("setProductCategories", catResponse.data.categories);
        }
        if (prodResponse.data.success) {
            commit("setProducts", prodResponse.data.products);
        }

        return true;
    },

    // Remove cart properties from products. This prevents them from displaying as though
    // they're in a cart when they aren't
    removeCartProperties(context) {

        let products = context.getters.getProducts;
        if (!products || products.length == 0) {
            return;
        }

        // Remove any cart properties from each product that has a Cart ID
        let productsInCart = products.filter(product => product.cartId);
        for (let i = 0; i < productsInCart.length; i++) {

            delete productsInCart[i].cartId;
            if (productsInCart[i].id) delete productsInCart[i].id;
            if (productsInCart[i].lineNumber) delete productsInCart[i].lineNumber;
            if (productsInCart[i].extendedPrice) delete productsInCart[i].extendedPrice;

            // Reinitialize quantity to pack size
            productsInCart[i].quantity = productsInCart[i].packSize;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
