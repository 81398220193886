/**
 * Shared User Vuex module for Suncast Portal and Admin Applications
 * @module UserStore
 * @author Scott Gingerysty (Fresche Solutions)
 * @date May 2020
 */

import { getField, updateField } from "vuex-map-fields";
import portalapi from "./portalapi";


const state = {
    userData: {},
    selectedUser: {},
    loggedIn: false,
    webUsers: null,
    userRoles: [],
    pageLimit: 5,
    userCustomers: {},
    customerList: [],
    selectedCustomer: {},
    customerWarehouses: [],
    selectedCustomerWarehouse: {},
    selectedCustomerInfo: null,
    dropShipInfo: {},
    countryList: [],
    stateList: [],
    constants: {
        USER_STATUSES: null,
        USER_TYPES: null,
    },
    defaults: {
    },
    registrations: null,
    selectedRegistration: {},
    portalHomepage: '',
    adminHomepage: '',
};

const getters = {
    getField,
    userDisplayName: (state) => {
        let firstName = state.userData.firstName;
        let lastName = state.userData.lastName;
        let userName = state.userData.userName;
        if (!firstName) {
            return userName;
        }
        return `${firstName} ${lastName}`;
    },
    getUserData: (state) => {
        return state.userData;
    },
    getLoggedIn: (state) => {
        return state.loggedIn;
    },
    getUserCustomers: (state) => {
        return state.userCustomers;
    },
    getSelectedCustomer: (state) => {
        return state.selectedCustomer;
    },
    getConstants: (state) => {
        return state.constants;
    },
    getDefaults(state) {
        return state.defaults;
    },
    // User Warehouses / Shipping Locations
    getCustomerWarehouses: (state) => {
        return state.customerWarehouses;
    },
    getDropShipInfo: (state) => {
        return state.dropShipInfo;
    },
    getSelectedCustomerWarehouse: (state) => {
        return state.selectedCustomerWarehouse;
    },
    getCountryList: (state) => {
        return state.countryList;
    },
    getStateList: (state) => {
        return state.stateList;
    },

    //selected customer info - used to display billing information on Checkout page
    getSelectedCustomerInfo: (state) => {
        return state.selectedCustomerInfo;
    },

    getUserHomepage: (state) => {
        return state.portalHomepage;
    },

    getAdminHomepage: (state) => {
        return state.adminHomepage;
    }
};

const mutations = {
    updateField, // for vuex-map-fields
    setLoggedIn(state, payload) {
        state.loggedIn = payload;
    },
    setUserData(state, payload) {
        state.userData = payload;
    },
    setUserLocation(state, payload) {
        state.userData.coLoc = payload;
    },
    setWebUsers(state, payload) {
        state.webUsers = payload;
    },
    setPageLimit(state, payload) {
        state.pageLimit = payload;
    },
    setCustomerList(state, payload) {
        state.customerList = payload;
    },
    setSelectedCustomer(state, payload) {
        state.selectedCustomer = payload;
        // reset the saved customer info
        state.selectedCustomerInfo = null;
    },
    setConstants(state, payload) {
        state.constants = payload;
    },
    setDefaults(state, payload) {
        state.defaults = payload;
    },
    setUserCustomers(state, payload) {
        state.userCustomers = payload;
    },
    setUserRole(state, payload) {
        state.userRoles = payload;
    },
    setUserWarehouses(state, payload) {
        state.customerWarehouses = payload;
    },
    setSelectedCustomerWarehouse(state, payload) {
        state.selectedCustomerWarehouse = payload;
    },
    setCountryList(state, payload) {
        state.countryList = payload;
    },
    setStateList(state, payload) {
        state.stateList = payload;
    },
    setDropShipInfo(state, payload) {
        state.dropShipInfo = payload;
    },
    setRegistrations(state, payload) {
        state.registrations = payload;
    },

    //selected customer info - used to display billing information on Checkout page
    setSelectedCustomerInfo(state, payload) {
        state.selectedCustomerInfo = payload;
    },

    setUserHomepage(state, userPermissions) {

        // Allowable home pages by permissions resource, in order of preference.
        // These permissions and page names are matched to what's in the router.
        const allPagesByResource = {};
        allPagesByResource.portal = {
            'Shopping': { pageName: 'Home', permission: 'shop' },
            'Orders': { pageName: 'OrderList', permission: 'list' },
            'Invoices': { pageName: 'InvoicesList', permission: 'all' },
            'Payment': { pageName: 'PaymentList', permission: 'all' },
        };
        allPagesByResource.admin = {
            'UserAdmin': { pageName: 'Users', permission: 'list' },
            'RoleAdmin': { pageName: 'Roles', permission: 'list' },
            'RegistrationAdmin': { pageName: 'Registration', permission: 'list' },
            'InventoryAdmin': { pageName: 'UpdateProductFiles', permission: 'all' },
        };

        let programs = Object.keys(allPagesByResource);
        for (let i = 0; i < programs.length; i++) {

            let program = programs[i];
            let pagesByResource = allPagesByResource[program];

            // Check if user has resource permission for each page in priority & set the first one found.
            for (let sectionResource in pagesByResource) {

                // Does the user have *access* to the required section resource?
                if (Object.keys(userPermissions).indexOf(sectionResource) != -1) {

                    // Does the user have the appropriate *permission* to the required section resource?
                    let pagePrivilege = pagesByResource[sectionResource].permission;
                    if (userPermissions[sectionResource].indexOf(pagePrivilege) != -1) {

                        if (program == 'admin') {
                            state.adminHomepage = pagesByResource[sectionResource].pageName;
                        } else {
                            state.portalHomepage = pagesByResource[sectionResource].pageName;
                        }
                        break;
                    }
                }
            }
        }
    },
};

const actions = {

    // Get the constants and defaults from the server
    async getConstants({ commit }) {
        const response = await portalapi.appAdmin.getConstants();
        if (response.data.codes) {
            commit("setConstants", response.data.codes);
        }
        if (response.data.defaults) {
            commit("setDefaults", response.data.defaults);
        }
    },
    
    // Get the constants and defaults from the server (just calls getConstants)
    async getDefaults(payload) {
        await payload.dispatch('getConstants', payload);
    },

    /**
     * Users
     */
    async getWebUsers({ commit }) {
        const response = await portalapi.appAdmin.getWebUsers();
        // call action to get customers
        var list = await portalapi.appAdmin.getAllCustomers();
        let customers = list.data.userCustomers;
        let users = response.data.users;
        users.map(item => {

            // Filter the customers for this webuser then map only the customer name
            var customersList = customers.filter(customer => {
                if (customer.userId == item.userId) {
                    return customer;
                }
            }).map(customer => {
                return ' ' + customer['customerName'];
            });

            item.customers = customersList;

        });
        // call function to map the customers to the user
        commit("setWebUsers", users);
    },

    async addUser({ commit }, userData) {
        return await portalapi.appAdmin.addUser(userData);
    },

    async updateUser({ commit }, userData) {
        return await portalapi.appAdmin.updateUser(userData);
    },

    async deleteUser({ commit }, userId) {
        return await portalapi.appAdmin.deleteUser(userId);
    },

    setPageLimit({ commit }, pageLimit) {
        commit("setPageLimit", pageLimit);
    },

    async fetchUserCustomers({ commit }) {

        // Ensure we have the required user id or we'll get a non-filtered list of customers
        if (state.userData.userId === undefined) {
            await actions.fetchUserData({ commit });
        }

        // Fetch the user's customer list
        const response = await portalapi.appPortal.fetchUserCustomers({
            params: { userId: state.userData.userId },
        });

        commit("setUserCustomers", response.data.userCustomers);
    },

    async login({ commit }, loginData) {
        const response = await portalapi.appPortal.login(loginData);
        if (response.data.success) {
            commit("setUserData", response.data.userData);
            commit("setLoggedIn", true);
            commit("setUserHomepage", response.data.userData.permissions);
        }
        return response;
    },

    async updateSessionData({ commit }, sessionData) {

        commit("setSelectedCustomer", sessionData.customer);

        if (sessionData.warehouse !== undefined) {
            commit("setSelectedCustomerWarehouse", sessionData.warehouse);
        }

        if (sessionData.dropShipInfo !== undefined) {
            commit("setDropShipInfo", sessionData.dropShipInfo);
        }

        // update the user data with any changes
        const response = await portalapi.appPortal.updateSessionData(sessionData);
        if (response.data.success) {
            commit("setUserData", response.data.userData);
        }

        return (response.data ? response.data : response);
    },

    async fetchUserData({ commit }) {
        const response = await portalapi.appPortal.fetchUserData();
        if (response.data.success) {
            commit("setUserData", response.data.userData);
            commit("setLoggedIn", true);
            commit("setUserHomepage", response.data.userData.permissions);
        } else {
            commit("setLoggedIn", false);
        }
        return response;
    },

    async logout({ commit }) {
        const response = await portalapi.appPortal.logout();
        if (response.data.success) {
            commit("setUserData", {});
            commit("setLoggedIn", false);
        }
    },
    async resetPassword({ commit }, resetData) {
        return await portalapi.appPortal.resetPassword(resetData);
    },

    async shippingAddressCheck({ commit }) {

        // Ensure we have the required user id or we'll get a non-filtered list of customers
        if (state.userData.userId === undefined) {
            await actions.fetchUserData({ commit });
        }
        let userData = state.userData;

        // Has a warehouse address
        if (userData.currentWarehouse && userData.currentWarehouse != '') {
            return true;
        }

        // Has a drop-ship address
        let hasDropShipInfo = (userData.currentDropShipInfo && Object.values(userData.currentDropShipInfo).length > 0 && userData.currentDropShipInfo.country !== null);
        if (hasDropShipInfo) {
            return true;
        }

        // No shipping address
        return {
            message: 'You must first select a shipping location',
            type: 'warning',
            redirect: 'WarehouseSelection',
        }
    },

    /**
     * Customers
     */
    async getCustomerList({ commit }) {
        const response = await portalapi.appAdmin.getCustomerList();
        commit("setCustomerList", response.data.customers);
    },

    async fetchSelectedCustomerInfo({ commit }) {
        const response = await portalapi.appPortal.fetchSelectedCustomerInfo(state.selectedCustomer.customerId);
        commit("setSelectedCustomerInfo", response.data.customer);
        return state.selectedCustomerInfo;
    },

    /**
     * Registrations
     */
    async getRegistrations({ commit }) {
        const response = await portalapi.appAdmin.getRegistrations();
        commit("setRegistrations", response.data.registrations);
    },
    async deleteRegistration({ commit }, registrationId) {
        return await portalapi.appAdmin.deleteRegistration(registrationId);
    },
    async updateRegistration({ commit }, registrationData) {
        return await portalapi.appAdmin.updateRegistration(registrationData);
    },

    /**
     * Shipping
     */
    async fetchCustomerWarehouses({ commit }) {

        // Ensure we have the required Customer ID
        let customerId = (state.selectedCustomer ? state.selectedCustomer.customerId : 0);
        if (customerId === undefined) {
            const response = await actions.fetchUserData({ commit });
            customerId = response.data.userData.currentCustomer;
        }

        // Fetch & save the user's customer list
        const response = await portalapi.appPortal.fetchCustomerWarehouses({
            params: { id: customerId },
        });
        commit("setUserWarehouses", (response.data.customerWarehouses ? response.data.customerWarehouses : []));
    },
    async fetchCountries({ commit }) {
        const response = await portalapi.appAdmin.getCountries();
        commit("setCountryList", response.data.countries);
    },
    async fetchStates({ commit }) {
        const response = await portalapi.appAdmin.getStateList();
        commit("setStateList", response.data.states);
    },
    async validateAddress({ commit }, payload) {
        const response = await portalapi.appAdmin.validateAddress(payload);
        return response.data
    }

};

export default {
    namespaced: "true",
    state,
    getters,
    mutations,
    actions,
};
