/**
 * Permission and User Authentication Routines
 * @author Chelsea Fenton (Fresche Solutions)
 * @date May 2020
 */

let authService = {
    isUserType(type){
        let userData = window.vm.$store.getters["User/getUserData"];
        return userData.userType == type;
    },
    userAuthResourcePriv(resource, privilege) {
        let userData = window.vm.$store.getters["User/getUserData"];

        // checked for logged in user data's existance
        if (!userData || !userData.permissions) {
            return false;
        }

        let permissions = userData.permissions;

        // look for the current route in the permissions
        let actions = permissions[resource];

        // if the resource was not found, or the privilege was not found
        return (actions != undefined && actions.indexOf(privilege) != -1);
    },
    userAuthRoute(to) {
        if (to.meta.resource == undefined || to.meta.privilege == undefined) {
            return true;
        }

        return authService.userAuthResourcePriv(to.meta.resource, to.meta.privilege);
    },
    userAuthRouteName(routeName, router) {
        let findRoute = router.resolve({
            name: routeName,
        });

        // route not found
        if (
            !findRoute ||
            !findRoute.route ||
            !findRoute.route.matched ||
            !findRoute.route.matched.length
        ) {
            return false;
        }

        return authService.userAuthRoute(findRoute.route);
    },
    requireAuth(to, from, next) {
        // if session not set properly - go to login
        if (!window.vm) {
            // save path for redirect after checking login
            window.localStorage.redirectTo = to.path;
            next("/pages/Login");
            return;
        }

        // check if user logged in
        let loggedIn = window.vm.$store.getters["User/getLoggedIn"];

        if (loggedIn) {
            if (authService.userAuthRoute(to)) {
                next();
            } else {
                next("/pages/NotAuth");
            }
        } else {
            // if not logged in - go to login
            next("/pages/Login");
        }
    },

    hasAdminPermissions() {
        if (authService.userAuthResourcePriv('UserAdmin', 'list')) return true;
        if (authService.userAuthResourcePriv('RoleAdmin', 'list')) return true;
        return false;
    },

    // Quotes Only: Can use a shopping cart but cannot submit an order from it
    canCreateQuotesOnly() {

        const hasCartAccess = (authService.userAuthResourcePriv('Shopping', 'shop'));
        const canSubmitOrder = (authService.userAuthResourcePriv('Shopping', 'pay'));

        return (hasCartAccess && !canSubmitOrder);
    },
};

export { authService };
