import "core-js/stable";
import "@babel/polyfill";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store/store";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueGtag from "vue-gtag";

// Going outside the portal project folder to get the shared BrowserCheck module
import BrowserCheck from "../../services/browserCheck";

Vue.use(
    VueGtag, {
        config: {
            id: process.env.VUE_APP_GTAG_ID
        }
    },
    router
);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

Vue.use(VueToast, {
    position: "top-right",
    duration: 4000
});


/**
 * Format a value to currency with dollar separators and dollar sign
 */
Vue.filter('currency', function(value) {

    if (!value) return '$0.00';

    value = Number.parseFloat(value).toFixed(2);

    // Work only with the dollar portion of the value
    let decimalPos = value.indexOf('.');
    let dollars = (decimalPos != -1 ? value.substring(0, decimalPos) : value);

    // Format the dollar portion of the value by starting at the end and
    // separating groups of three digits with commas
    let formatted = '';
    for (let i = dollars.length - 3; i > 0; i -= 3) {

        let curPortion = dollars.substring(i);
        if (curPortion.length == 3) {
            formatted = curPortion + (formatted != '' ? ',' : '') + formatted;
            dollars = dollars.substring(0, i);
        }
    }

    // Add the last - or only - portion of the value
    if (dollars != '') {
        formatted = dollars + (formatted != '' ? ',' : '') + formatted;
    }

    // Append the decimal portion if there is one
    if (decimalPos != -1) {
        formatted = formatted + value.substring(decimalPos);
    }

    return '$' + formatted;
});

/**
 * Format a value to weight
 */
Vue.filter('weight', function(value) {

    if (!value) return '0.000';

    value = Number.parseFloat(value).toFixed(3);

    return value + ' lbs';
});

/**
 * Format a value to volume
 */
Vue.filter('volume', function(value) {

    if (!value) return '0.000';

    value = Number.parseFloat(value).toFixed(3);

    return value + ' ft3';
});

/**
 * Format a value to length
 */
Vue.filter('length', function(value) {

    if (!value) return '0.000';

    value = Number.parseFloat(value).toFixed(3);

    return value + ' in';
});

window.vm = new Vue({
    el: "#app",
    router,
    store,
    icons,
    template: "<App/>",
    components: {
        App
    }
});


// Display an alert message if the user is using IE
let browserType = BrowserCheck.getBrowserType();
if (browserType == 'IE') {
    let warningHtml = '<div class="alert alert-warning">Sorry, Internet Explorer is no longer supported. Please install a more up to date browser such as Edge, Chrome, Firefox, or Opera.</div>';
    document.body.innerHTML = warningHtml + document.body.innerHTML;
}

