import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () =>
    import ("@/containers/TheContainer");

// Views - Pages
const Page404 = () =>
    import ("@/views/pages/Page404");
const Page500 = () =>
    import ("@/views/pages/Page500");
const NotAuth = () =>
    import ("@/views/pages/NotAuth");
const Login = () =>
    import ("@/views/pages/Login");
const Register = () =>
    import ("@/views/pages/Register");
const ForgotPassword = () =>
    import ("@/views/pages/ForgotPassword");
const ResetPassword = () =>
    import ("@/views/pages/ResetPassword");
const CustomerSelection = () =>
    import ("@/views/pages/CustomerSelection");
const WarehouseSelection = () =>
    import ("@/views/pages/WarehouseSelection");
const DropShipEntry = () =>
    import ("@/views/pages/DropShipEntry");
const Profile = () =>
    import ("@/views/pages/Profile");

// Order List History
const Orderlist = () =>
    import ("@/views/order/OrderList");

//Order Detail Component
const OrderDetails = () =>
    import ("@/views/order/OrderDetails");

// Products
const ShopProducts = () =>
    import ("@/views/shop/Products");
const Product = () =>
    import ("@/views/shop/Product");

// Display Active Cart
const Cart = () =>
    import ("@/views/shop/Cart");

// Display Saved Cart List
const SavedCartsList = () =>
    import ("@/views/savedcarts/SavedCartsList");

// Display Saved Cart
const SavedCartDisplay = () =>
    import ("@/views/savedcarts/SavedCartDisplay");

// Account Registration
const AccountRegistration = () =>
    import ("@/views/account/AccountRegistration");
// Invoice List
const InvoiceList = () =>
    import ("@/views/invoice/InvoiceList");

// Invoice Details
const InvoiceDetails = () =>
    import ("@/views/invoice/InvoiceDetails");

// Pay Invoices
const PayInvoices = () =>
    import ("@/views/invoice/PayInvoices");

// Payment List
const PaymentList = () =>
    import ('@/views/payments/PaymentList');

// Payment Details
const PaymentDetails = () =>
    import ('@/views/payments/PaymentDetails');

// Payment Confirmation
const PaymentConfirmation = () =>
    import ("@/views/invoice/PaymentConfirmation");

// Payment Receipt
const PaymentReceipt = () =>
    import ("@/views/payment/PaymentReceipt");

// Checkout Page
const Checkout = () =>
    import ("@/views/shop/Checkout");

// Review Order Page
const ReviewOrder = () =>
    import ("@/views/shop/ReviewOrder");

// Pay Now Page
const PayNow = () =>
    import ("@/views/payment/PayNow");

Vue.use(Router);

export default new Router({
    mode: "hash", // https://router.vuejs.org/api/#mode
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
});

import { authService } from "../../../services/authService";

function configRoutes() {
    return [{
            path: "/",
            redirect: "/shop",
            name: "Home",
            component: TheContainer,
            beforeEnter: authService.requireAuth,
            children: [{
                    name: 'ShopProducts',
                    path: "shop/:category?/:subCategory?/:categoryName?/:subCategoryName?",
                    meta: {
                        label: "Shop Products",
                        resource: "Shopping", // resource to use in permissions
                        privilege: "shop" // privilege to use in permissions
                    },
                    component: ShopProducts
                },
                {
                    path: "product/:productId/:productName",
                    name: "ProductDetail",
                    meta: {
                        label: "Product Detail",
                        resource: "Shopping", // resource to use in permissions
                        privilege: "shop" // privilege to use in permissions
                    },
                    component: Product
                },
                // Cart
                {
                    path: "cart",
                    name: "Cart",
                    meta: {
                        label: "Cart",
                        resource: "Shopping", // resource to use in permissions
                        privilege: "shop" // privilege to use in permissions
                    },
                    component: Cart
                },
                // Cart with children to checkout
                {
                    path: "cart",
                    name: "CartChild",
                    component: {
                        render(c) {
                            return c("router-view");
                        }
                    },
                    meta: {
                        label: "Cart",
                        resource: "Shopping", // resource to use in permissions
                        privilege: "shop" // privilege to use in permissions
                    },
                    children: [
                        // Checkout
                        {
                            path: "checkout",
                            name: "Checkout",
                            component: Checkout,
                            meta: {
                                label: "Checkout",
                                resource: "Shopping", // resource to use in permissions
                                privilege: "shop" // privilege to use in permissions
                            }
                        },
                        // Checkout with child to review order
                        {
                            path: "checkout",
                            name: "CheckoutChild",
                            meta: {
                                label: "Checkout",
                                resource: "Shopping", // resource to use in permissions
                                privilege: "shop" // privilege to use in permissions
                            },
                            component: {
                                render(c) {
                                    return c("router-view");
                                }
                            },
                            children: [
                                // Review Order
                                {
                                    path: "reviewOrder",
                                    name: "ReviewOrder",
                                    component: ReviewOrder,
                                    meta: {
                                        label: "ReviewOrder",
                                        resource: "Shopping", // resource to use in permissions
                                        privilege: "shop" // privilege to use in permissions
                                    }
                                },
                                // Review Order with child to paynow
                                {
                                    path: "reviewOrder",
                                    name: "ReviewOrderChild",
                                    meta: {
                                        label: "ReviewOrder",
                                        resource: "Shopping", // resource to use in permissions
                                        privilege: "shop" // privilege to use in permissions
                                    },
                                    component: {
                                        render(c) {
                                            return c("router-view");
                                        }
                                    },
                                    // Pay Now (Submit an order) requires all shopping permissions, not just cart
                                    children: [{
                                        path: "payNow",
                                        name: "PayNow",
                                        component: PayNow,
                                        meta: {
                                            label: "Payment",
                                            resource: "Shopping", // resource to use in permissions
                                            privilege: "pay" // privilege to use in permissions
                                        }
                                    }]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "orders",
                    meta: {
                        label: "Orders",
                        title: "Suncast - Orders"
                    },
                    component: {
                        render(c) {
                            return c("router-view");
                        }
                    },
                    children: [{
                            path: "",
                            name: "OrderList",
                            meta: {
                                label: "Order List History",
                                resource: "Orders", // resource to use in permissions
                                privilege: "list" // privilege to use in permissions
                            },
                            component: Orderlist
                        },
                        {
                            path: "display/:id",
                            name: "OrderDetails",
                            meta: {
                                label: "Order Details",
                                resource: "Orders", // resource to use in permissions
                                privilege: "display" // privilege to use in permissions
                            },
                            component: OrderDetails
                        }
                    ]
                },
                {
                    path: "invoices",
                    meta: {
                        label: "Invoices",
                        title: "Suncast - Invoices"
                    },
                    component: {
                        render(c) {
                            return c("router-view");
                        }
                    },
                    children: [{
                            path: "",
                            name: "InvoicesList",
                            meta: {
                                label: "Invoices",
                                resource: "Invoices", // resource to use in permissions
                                privilege: "all" // privilege to use in permissions
                            },
                            component: InvoiceList,
                            beforeEnter: authService.requireAuth
                        },
                        {
                            path: "paymentConfirmation",
                            name: "PaymentConfirmation",
                            meta: {
                                label: "Payment Confirmation",
                                resource: "Invoices", // resource to use in permissions
                                privilege: "all" // privilege to use in permissions
                            },
                            component: PaymentConfirmation,
                            beforeEnter: authService.requireAuth
                        },
                        {
                            path: "paymentReceipt/:id",
                            meta: {
                                label: "Payment Receipt",
                                title: "Suncast - Receipt",
                                resource: "Invoices", // resource to use in permissions
                                privilege: "all" // privilege to use in permissions
                            },
                            component: PaymentReceipt
                        },
                        {
                            path: "payInvoices",
                            name: "PayInvoices",
                            meta: {
                                label: "Pay Invoices",
                                resource: "Invoices", // resource to use in permissions
                                privilege: "all" // privilege to use in permissions
                            },
                            component: PayInvoices,
                            beforeEnter: authService.requireAuth
                        },
                        {
                            path: "display/:id",
                            name: "InvoiceDetails",
                            meta: {
                                label: "Invoice Details",
                                resource: "Invoices", // resource to use in permissions
                                privilege: "display" // privilege to use in permissions
                            },
                            component: InvoiceDetails,
                            beforeEnter: authService.requireAuth
                        }
                    ]
                },
                {
                    path: "payments",
                    meta: {
                        label: 'Payments',
                        title: 'Suncast - Payments'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                            path: '',
                            name: "PaymentList",
                            meta: {
                                label: "Invoice Payments",
                                resource: "Payment", // resource to use in permissions
                                privilege: "all" // privilege to use in permissions
                            },
                            component: PaymentList,
                            beforeEnter: authService.requireAuth,
                        },
                        {
                            path: 'display/:paymentId/:paymentDate',
                            name: 'PaymentDetails',
                            meta: {
                                label: 'Payment Details',
                                resource: 'Payment',
                                privilege: 'all',
                            },
                            component: PaymentDetails,
                            beforeEnter: authService.requireAuth,
                        },
                    ]
                },
                {
                    path: "savedcarts",
                    meta: {
                        label: "Saved Carts",
                        title: "Saved Carts"
                    },
                    component: {
                        render(c) {
                            return c("router-view");
                        }
                    },
                    children: [{
                            path: "",
                            name: "SavedCartsList",
                            meta: {
                                label: "Saved Cart List",
                                resource: "Shopping", // resource to use in permissions
                                privilege: "shop" // privilege to use in permissions
                            },
                            component: SavedCartsList,
                            beforeEnter: authService.requireAuth
                        },
                        {
                            path: "savedCartDisplay/:id",
                            name: "Cart Details",
                            meta: {
                                label: "Cart Details",
                                resource: "Shopping", // resource to use in permissions
                                privilege: "shop"
                            },
                            component: SavedCartDisplay,
                            beforeEnter: authService.requireAuth
                        }
                    ]
                }
            ]
        },
        {
            path: "/pages",
            redirect: "/pages/404",
            name: "Pages",
            component: {
                render(c) {
                    return c("router-view");
                }
            },
            children: [{
                    path: "404",
                    name: "Page404",
                    component: Page404
                },
                {
                    path: "500",
                    name: "Page500",
                    component: Page500
                },
                {
                    path: "notauth",
                    name: "NotAuth",
                    component: NotAuth
                },
                {
                    path: "login",
                    name: "Login",
                    component: Login
                },
                {
                    path: "accountRegistration",
                    name: "Account Registration",
                    meta: {
                        label: "Account Registration"
                    },
                    component: AccountRegistration
                },
                {
                    path: "forgotPassword",
                    name: "ForgotPassword",
                    component: ForgotPassword
                },
                {
                    path: "resetPassword",
                    name: "ResetPassword",
                    component: ResetPassword
                },
                {
                    path: "customerSelection",
                    name: "CustomerSelection",
                    component: CustomerSelection
                },
                {
                    path: "profile",
                    name: "Profile",
                    component: Profile
                },
                {
                    path: "warehouseSelection",
                    name: "WarehouseSelection",
                    component: WarehouseSelection
                },
                {
                    path: "dropShipEntry",
                    name: "DropShipEntry",
                    component: DropShipEntry
                },
            ]
        }
    ];
}
