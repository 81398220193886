/**
 * Brain Tree Vuex module for Suncast Portal Application
 * Controls the data and actions for Brain Tree integration
 * @module Product
 * @see
 * @author Chelsea Fenton (Fresche Solutions)
 * @date July 2020
 */

import portalapi from "../../../../services/portalapi";
import { getField, updateField } from "vuex-map-fields";

const state = {
    authorization: null,
};

const getters = {
    getField, // for vuex-map-fields,
    getAuthorization(st) {
        return st.authorization;
    },
};

const mutations = {
    updateField, // for vuex-map-fields
    setAuthorization(st, payload) {
        st.authorization = payload;
    },
};

const actions = {
    // get authorization token
    async fetchAuthorization({ commit }) {
        var response = await portalapi.moduleBrainTree.getAuthorization();
        commit("setAuthorization", response.data.clientToken);
        return response.data.clientToken;
    },
    // post payment method
    async postPaymentMethod({ commit }, data) {
        var response = await portalapi.moduleBrainTree.postPaymentMethod(data);
        return response.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
