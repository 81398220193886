/**
 * Order Vuex module for Suncast Portal Application
 * Controls the actions, data and state of Orders for the portal application
 * @module Order
 * @see
 * @author Mithun Paulose (Fresche Solutions)
 * @date June 2020
 */

import portalapi from "../../../../services/portalapi";
import { getField, updateField } from "vuex-map-fields";

// Valid cart types
const CART_TYPE_ACTIVE = 'A';
const CART_TYPE_SAVED = 'S';
const CART_TYPE_TEMP = 'T';

const DEFAULT_NEW_ORDER = {
    terms: "",
    attentionTo: "",
    count: 0,
    customerId: "",
    customerPO: "",
    estimatedShipDate: "",
    id: "",
    lineItems: null,
    name: "",
    notes: "",
    orderDate: "",
    phone: "",
    billToAddress1: "",
    billToAddress2: "",
    billToAddress3: "",
    billToCity: "",
    billToCountry: "",
    billToName: "",
    billToState: "",
    billToZip: "",
    shipToAddress1: "",
    shipToAddress2: "",
    shipToAddress3: "",
    shipToCity: "",
    shipToCountry: "",
    shipToName: "",
    shipToState: "",
    shipToZip: "",
    shippingCost: 0,
    shippingMethod: "",
    shippingOptions: null,
    customerFobList: null,
    shippingNumber: "",
    status: "",
    stockLocation: "",
    subtotal: 0,
    tax: 0,
    total: 0,
    type: "",
    userId: "",
    warehouse: "",
    weight: 0
};

const INITIAL_STATE = {
    emptyOrder: DEFAULT_NEW_ORDER,
    activeOrder: null,
    shippingMethods: null,
    estimatedShipDate: null,
    
    // Temporary Order ID that may have been created server-side when calculating shipping 
    // costs and can be sent back for deletion upon submitting an order.
    tempOrderId: null,
};

//Creates a new Order by copying a default order state
function getNewOrderDefaults() {
    let order = JSON.parse(JSON.stringify(DEFAULT_NEW_ORDER));
    return order;
}

const state = {...INITIAL_STATE };

const getters = {
    getField,
    getActiveOrder() {
        return (state.activeOrder ? state.activeOrder : state.emptyOrder);
    },
    getShippingMethods() {
        return state.shippingMethods;
    },
    getTempOrderId() {
        return state.tempOrderId;
    },
    getShippingOptions() {
        return state.shippingOptions;
    },
    getCustomerFobList() {
        return state.customerFobList;
    },
    getEstimatedShipDate() {
        return state.estimatedShipDate;
    }
};

const mutations = {
    updateField,
    resetOrderData(state) {
        let newState = {...INITIAL_STATE };
        state = Object.assign(state, newState);
    },
    setActiveOrder(state, payload) {
        state.activeOrder = payload;
    },
    setShippingMethods(state, payload) {
        state.shippingMethods = payload;
    },
    setTempOrderId(state, payload) {
        state.tempOrderId = payload;
    },
    setShippingOptions(state, payload) {
        if (!state.shippingOptions) {
            state.shippingOptions = [];
        }
        state.shippingOptions[payload.carrierType] = payload.shippingOptions;
    },
    setFobList(state, payload) {
        if (payload.fobList && payload.fobList.length > 0) {
            state.customerFobList = payload.fobList;
        }
    },
    setEstimatedShipDate(state, payload) {
        state.estimatedShipDate = payload;
    }
};

const actions = {
    createActiveOrderFromCart({ commit }, cart) {
        commit("setActiveOrder", cart);
    },

    // Fetch the estimated ship date
    async fetchEstimatedShipDate({ commit }) {
        let response = await portalapi.moduleShopping.fetchShipDate();
        if (response.data.success) {
            commit("setEstimatedShipDate", response.data.estimatedShipDate);
        }
        return response.data;
    },

    /**
     * Fetch the shipping methods by submitting the cart data as a temporary order and
     * then fetching the shipping methods based on the temp order ID, if one was provided.
     */
    async fetchShippingMethods({ commit }, params) {

        // First, post cart data to the server to create a temp order. We need the temp ID it
        // returns to retrieve the shipping methods (for non-Collect orders only at this time
        // but we leave the business logic to the API).
        const cartData = {...params.cart};
        cartData.cartId = cartData.id;      // Copy the cart ID as cartId for the order
        cartData.type = CART_TYPE_TEMP;     // Temporary cart/order flag

        const postTempOrderResponse = await portalapi.moduleShopping.postOrder(cartData);
        if (!postTempOrderResponse.data.success) {
            return postTempOrderResponse.data;
        }

        // If a temporary Order ID was provided, set it. This can be sent back for deletion 
        // upon submitting an order.
        if (postTempOrderResponse.data.tempOrderId) {
            commit('setTempOrderId', postTempOrderResponse.data.tempOrderId);
        }

        // Now that we have a temp order ID, fetch the shipping methods with only the necessary data.
        const shippingMethodsResponse = await portalapi.moduleShopping.fetchShippingMethods({
            tempOrderId: postTempOrderResponse.data.tempOrderId || '',
            fob: params.fob || cartData.fob,
            destinationType: cartData.destinationType,
            liftGateRequired: cartData.liftGateRequired,
        });
        if (shippingMethodsResponse.data.success) {
            commit('setShippingMethods', shippingMethodsResponse.data.shippingMethods);
        }
        
        return shippingMethodsResponse.data;
    },

    // Fetch the shipping options
    async fetchShippingOptions({ commit }, carrierType) {
        let response = await portalapi.moduleShopping.fetchShippingOptions(carrierType);
        if (response.data.success) {
            commit("setShippingOptions", response.data);
            return state.shippingOptions[carrierType];
        }
    },

    // Fetch a customer's FOB options list
    async fetchCustomerFobList({ commit }, params) {
        let response = await portalapi.moduleShopping.fetchCustomerFobList(params);
        if (response.data.success) {
            commit("setFobList", response.data);
            return state.customerFobList;
        }
    },

    async updateActiveOrder({ commit }, activeOrder) {
        activeOrder.cartId = activeOrder.id;
        let response = await portalapi.moduleShopping.updateActiveOrder(
            activeOrder
        );
        return response.data.success ? true : response.data.errors;
    },

    // post the active order
    async postOrder({ commit }, activeOrder) {
        activeOrder.cartId = activeOrder.id;
        let response = await portalapi.moduleShopping.postOrder(activeOrder);
        return response.data.success ? true : response.data.errors;
    }
};

export default {
    namespaced: "true",
    state,
    getters,
    mutations,
    actions
};