/**
 * Shared Role Vuex module for Suncast Portal and Admin Applications
 * @module RoleStore
 * @author Kevin Jones (Fresche Solutions)
 * @date May 2020
 */

import { getField, updateField } from "vuex-map-fields";
import portalapi from "./portalapi";

const state = {
    roleData: {},
    roleList: [],
    selectedRole: {},
    pageLimit: 5,
};

const getters = {
    getField,
    getRoleData: (state) => {
        return state.roleData;
    },
};

const mutations = {
    updateField, // for vuex-map-fields
    setRoles(state, payload) {
        state.roleList = payload;
    },
    setRoleData(state, payload) {
        state.roleData = payload;
    },
    setPageLimit(state, payload) {
        state.pageLimit = payload;
    },
};

const actions = {
    async getRoles({ commit }) {
        var response = await portalapi.appAdmin.getRoles();
        commit("setRoles", response.data.roles);
    },
    async addRole({ commit }, roleData) {
        return await portalapi.appAdmin.addRole(roleData);
    },
    async updateRole({ commit }, roleData) {
        return await portalapi.appAdmin.updateRole(roleData);
    },
    async deleteRole({ commit }, roleData) {
        return await portalapi.appAdmin.deleteRole(roleData);
    },
    setPageLimit({ commit }, pageLimit) {
        commit("setPageLimit", pageLimit);
    },
};

export default {
    namespaced: "true",
    state,
    getters,
    mutations,
    actions,
};
