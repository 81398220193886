/**
 * Suncast Application API module
 * Contains all API functionality to/from the server for the application
 * @module portalapi
 * @author Scott Gingerysty (Fresche Solutions)
 * @date April 2020
 */

import axios from "axios";

import adminStore from "../admin/src/store/store";
import portalStore from "../portal/src/store/store";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;

const API_DATE_FORMAT = "YYYY-MM-DD-HH.mm.ss";
let basePath;

// If in local mode use proxy server
if (process.env.VUE_APP_ENVIRONMENT === "local") {
    basePath = process.env.VUE_APP_DEV_PROXY;
} else {
    basePath = process.env.VUE_APP_API_PATH;
}

const qs = require("qs");

let setCommonHeader = (headerName, headerVal) => {
    axios.defaults.headers.common[headerName] = headerVal;
};

// The audit user is used in URL paths for simple logging of actions
// via web server logs - it's not used by the application
let auditUser = "PORTAL_API_NOT_LOGGED_IN";
let setAuditUser = (user) => {
    auditUser = user;
};

axios.interceptors.request.use(function(request) {

    // Set spinners on if we're using them
    if (adminStore.state.useLoading) {
        adminStore.state.isLoading = true;
    }

    if (portalStore.state.useLoading) {
        portalStore.state.isLoading = true;
    }

    return request;
});

// If any API calls fail with no auth or auth expired redirect user to login
axios.interceptors.response.use(
    function(response) {

        // Set spinners off
        adminStore.state.isLoading = false;
        portalStore.state.isLoading = false;
        return response;
    },
    function(error) {

        console.error(error);

        // Set spinners off
        adminStore.state.isLoading = false;
        portalStore.state.isLoading = false;

        // Create an error message
        if (error.response && error.response.data && error.response.data.title) {
            let errMessage = error.response.data.title;

            // Add the detail if there is any
            if (error.response.data.detail) {
                errMessage = errMessage + ': ' + error.response.data.detail;
            }

            // Show the error with a generic default if none was retrieved
            vm.$toast.open({
                message: errMessage,
                type: "error",
            });
        }

        // Not authorized redirect to login
        if (error.response && error.response.status === 401) {
            window.vm.$router.push("/login");
        }
        return Promise.reject(error);
    }
);

let appAdmin = {
    async getConstants() {
        return axios
            .get(`${basePath}/constants`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async getWebUsers() {
        return axios
            .get(`${basePath}/user`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async updateUser(userData) {
        return axios
            .post(`${basePath}/user`, userData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async addUser(userData) {
        return axios
            .post(`${basePath}/user`, userData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async deleteUser(userID) {
        return axios
            .delete(`${basePath}/user`, { data: { userId: userID } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Roles
    async getRoles(data) {
        return axios
            .get(`${basePath}/role`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async addRole(data) {
        return axios
            .post(`${basePath}/role`, data)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async updateRole(data) {
        return axios
            .post(`${basePath}/role`, data)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async deleteRole(roleId) {
        return axios
            .delete(`${basePath}/role`, { data: { id: roleId } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // User Roles
    async getUserRoles(data) {
        return axios
            .get(`${basePath}/userrole`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async addUserRole(data) {
        return axios
			.post(`${basePath}/userrole`, data)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async deleteUserRole(data) {
        return axios
            .delete(`${basePath}/userrole`, { data: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    async getDrivers() {
        return axios
            .get(`${basePath}/usermaint/getDrivers`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async sendResetPassword(userID) {
        return axios
			.post(
                `${basePath}/login/send-reset-password`,
                qs.stringify({
                    userName: userID,
                    app: "dispatch",
                }))
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async validateUserEmail(userData) {
        return axios
			.post(`${basePath}/usermaint/validateUserEmail`, userData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async validateUserEdit(userData) {
        return axios
			.post(`${basePath}/usermaint/validateUserEdit`, userData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async getLocationData() {
        return axios
            .get(`${basePath}/usermaint/getLocationData`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async setUserLocation(location) {
        return axios
			.post(`${baseAPIPath}/dispatch/setUserLocation`, location)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Customer List
    async getCustomerList() {
        return axios
            .get(`${basePath}/customer`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // User's Customers
    async getUserCustomers(data) {
        return axios
            .get(`${basePath}/usercustomer`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async addUserCustomer(data) {
        return axios
			.post(`${basePath}/usercustomer`, data)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async deleteUserCustomer(data) {
        return axios
            .delete(`${basePath}/usercustomer`, { data: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // All Customers
    async getAllCustomers() {
        return axios
            .get(`${basePath}/usercustomer`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Registration
    async getRegistrations(data) {
        return axios
            .get(`${basePath}/registration`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async deleteRegistration(registrationId) {
        return axios
            .delete(`${basePath}/registration`, { data: { 'id': registrationId } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async updateRegistration(data) {
        return axios
			.post(`${basePath}/registration`, data)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Shipping
    async getCountries() {
        return axios
            .get(`${basePath}/countries`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async getStateList() {
        return axios
            .get(`${basePath}/states`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Address validation
    async validateAddress(data) {
        return await axios
            .get(`${basePath}/addressvalidation`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Update Product Documents
    async updateProductDocuments(csvFile) {
        return await axios
            .get(`${basePath}/products`, { params: { 'csvFile': csvFile } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

};

let appPortal = {
    async login(loginData) {
        return axios
			.post(`${basePath}/login`, loginData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async updateSessionData(sessionData) {
        return axios
            .put(`${basePath}/login`, sessionData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async fetchUserData() {
        return axios
            .get(`${basePath}/userdata`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async fetchUserCustomers(userData) {
        return axios
            .get(`${basePath}/usercustomer`, userData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async fetchCustomerWarehouses(customerData) {
        return axios
            .get(`${basePath}/customerwarehouse`, customerData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async fetchSelectedCustomerInfo(customerId) {
        return axios
            .get(`${basePath}/customer`, {
                params: {
                    id: customerId
                }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async logout() {
        return axios
			.post(`${basePath}/logout`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async resetPassword(resetData) {
        return axios
			.post(`${basePath}/resetpassword`, resetData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async registerAdd(registrationData) {
        return axios
			.post(`${basePath}/registration`, registrationData)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async documentsAdd(data) {
        return axios
			.post(`${basePath}/fileupload`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
};

let moduleShopping = {

    /**
     * Products
     */
    async getProductCategories(data) {
        return axios
            .get(`${basePath}/categories`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async getProducts(data) {
        return axios
            .get(`${basePath}/products`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async getProductDetail(productId) {
        return axios
            .get(`${basePath}/products`, {
                params: {
                    part: productId
                }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    /**
     * Cart
     */
    // Get a user's carts - both saved and active - for the current customer
    async getCustomerCarts(data) {

        let filters = {
            userId: data.userId,
            customerId: data.customerId,
        };

        // Add the cart type filter if provided
        if (data.type) {
            filters.type = data.type;
        }

        return axios
            .get(`${basePath}/cart`, { params: filters })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Get the active cart from session
    async getActiveCart(data) {
        return axios
            .get(`${basePath}/cart`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Save the active cart in session
    async saveActiveCart(cartName, type) {
        return axios
			.post(`${basePath}/cart`, { name: cartName, type: type })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Switch active cart in session to another
    async switchActiveCart(cartId) {
        return axios
			.post(`${basePath}/cart`, { activeId: cartId })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Add an item to the active cart in session
    async addCartItem(data) {
        return axios
			.post(`${basePath}/cart/item`, {
                'itemId': data.part,
                'quantity': data.quantity,
                'price': data.price,
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Update an item's quantity in the active cart in session
    async updateCartItemQuantity(data) {
        return axios
			.post(`${basePath}/cart/item`, {
                'id': data.id,
                'quantity': data.quantity,
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Remove an item from the active cart in session
    async removeCartItem(id) {
        return axios
            .delete(`${basePath}/cart/item`, {
                data: {
                    id: id
                }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    // Delete the active cart
    async deleteCart(cartId) {
        return axios
            .delete(`${basePath}/cart`, { data: { id: cartId } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Order Cart

    // Shipping methods
    async fetchShippingMethods(data) {
        return axios
            .get(`${basePath}/shippingmethods`, {
                params: data
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Shipping options
    async fetchShippingOptions(carrierType) {
        return axios
            .get(`${basePath}/shippingoptions`, {
                params: { carrierType: carrierType }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Fetch a customer's FOB options list. Paremeter, params, must contain customerId & shipToCountry
    async fetchCustomerFobList(params) {
        return axios
            .get(`${basePath}/customerfoblist`, {
                params: {
                    id: params.customerId,
                    shipToCountry: params.shipToCountry,
                }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Estimated ship date
    async fetchShipDate(data) {
        return axios
            .get(`${basePath}/estimatedshipdate`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Update the cart/order data
    async updateActiveOrder(cart) {
        return axios
			.post(`${basePath}/cart`, cart)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Post a cart to order
    async postOrder(cart) {
        return axios
			.post(`${basePath}/orders`, cart)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Handle an Axios error. This includes such things as an error or no response response response.
    // If err.response, client received an error response (5xx, 4xx)
    // If err.request, client never received a response, or request never left 
    handleCaughtAxiosError(err) {
        return {'data': {
            'success': false,
            'errors': {
                'GLOBAL': err.message,
            },
        }};
    },
};

let moduleOrders = {

    // Order List History
    async getOrderList(data) {
        // Data equals user id (?)
        return axios
            .get(`${basePath}/orders`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Order Details
    async getOrderDetails(orderNumber) {
        // Data equals order id (?)
        return axios
            .get(`${basePath}/orders`, { params: { orderNumber: orderNumber } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Delete Order
    async deleteOrder(orderNumber) {
        return axios
            .delete(`${basePath}/orders`, { data: { orderNumber: orderNumber } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Delete Order Item(s)
    async deleteOrderItems(orderNumber, itemList) {
        return axios
            .delete(`${basePath}/orderitems`, { data: { orderNumber: orderNumber, items: itemList } })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

};

let moduleInvoices = {
    // Single invoice if an invoice number is passed or invoicelist instead
    async getInvoices(data) {
        return axios
            .get(`${basePath}/invoices`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

};

let modulePayments = {

    // Get payment details
    async getPaymentDetails(data) {
        return axios
            .get(`${basePath}/payment`, { params: data })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },

    // Get payments list by date range
    async getPayments(queryData) {

        // Get payments for the selected customer in session for the date range provided
        let response = await axios
            .get(`${basePath}/invoicepayments`, {
                params: {
                    from: queryData.fromDate,
                    to: queryData.toDate,
                }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });

        return response.data;
    },

    // Get a payment's invoice list
    async getPaymentInvoices(paymentId) {

        // Get payments for the selected customer in session for the date range provided
        let response = await axios
            .get(`${basePath}/paymentinvoices`, {
                params: {
                    paymentId: paymentId,
                }
            })
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });

        return response.data;
    },
};

let moduleBrainTree = {
    // Brain Tree
    async getAuthorization() {
        return axios
            .get(`${basePath}/brainTree`)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
    async postPaymentMethod(data) {
        return axios
			.post(`${basePath}/brainTree`, data)
            .catch(err => {
                return this.handleCaughtAxiosError(err);
            });
    },
};


export default {
    setCommonHeader,
    setAuditUser,
    API_DATE_FORMAT,
    appAdmin,
    appPortal,
    moduleShopping,
    moduleOrders,
    moduleInvoices,
    modulePayments,
    moduleBrainTree,
};
