/**
 * Suncast Portal Application Vuex store file
 * Controls data for the Portal
 * @module store
 * @see admin
 * @author Scott Gingerysty (Fresche Solutions)
 * @date May 2020
 */

import Vue from "vue";
import Vuex from "vuex";

import portalapi from "../../../services/portalapi";
import { getField, updateField } from "vuex-map-fields";

// Going outside the admin project folder to get the shared user store module
// @ is aliased to the src folder
import User from "../../../services/user-store";
import OrderHistory from "../../../services/order-store";
import Invoice from "../../../services/invoice-store";
import Payment from "../../../services/payment-store";
import Cart from "./modules/Cart"
import Product from "./modules/Product";
import Order from "./modules/Order";
import BrainTree from "./modules/BrainTree";
import Payments from "./modules/Payments";

Vue.use(Vuex);

const state = {
  sidebarShow: "toggleSidebarDesktop",
  sidebarMinimize: false,
  isLoading: false,
  useLoading: true,
};

const getters = {
  getField, // for vuex-map-fields
};

const mutations = {
  updateField, // for vuex-map-fields
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed;
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },

  // Loading indicator - on/off
  enableLoadingIndicator(state) {
    state.useLoading = true;
  },
  disableLoadingIndicator(state) {
    state.useLoading = false;
  },
};

const actions = {};

let portalStore = new Vuex.Store({
  namespaced: true,
  state,
  modules: {
    User,
    Cart,
    OrderHistory,
    Product,
    Invoice,
    Payment,
    Order,
    BrainTree,
    Payments
  },
  mutations,
  actions,
  getters,
});

export default portalStore;
