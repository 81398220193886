/**
 * Payments Vuex module for Suncast Portal Application
 * Controls the actions, data and state of Payments for the portal application
 * @module Payments
 * @author Kevin Jones (Fresche Solutions)
 * @date July 2020
 */

import portalapi from "../../../../services/portalapi";
import { getField, updateField } from "vuex-map-fields";

const INITIAL_STATE = {
    pageLimit: 20,
    recordLimit: null,
    payments: null,
    selectedPayment: null,
    paymentInvoices: null, // List of invoices by payment
    cumulatedInvoiceTotal: 0,
    cumulatedPaymentTotal: 0,
    fromDate: null,
    toDate: null,
};

const state = {...INITIAL_STATE };


const getters = {

    // Get the "From" Date
    getFromDate(state) {
        return state.fromDate;
    },

    // Get the "To" Date
    getToDate(state) {
        return state.toDate;
    },

    // Get the payment list
    getPayments(state) {
        return state.payments;
    },

    // Get the selected payment
    getSelectedPayment(state) {
        return state.selectedPayment;
    },

    // Get the selected payment
    getPaymentInvoices(state) {
        return state.paymentInvoices;
    },

    // Get cumulated invoice totals
    getCumulatedInvoiceTotal(state) {
        return state.cumulatedInvoiceTotal;
    },

    // Get cumulated payment totals
    getCumulatedPaymentTotal(state) {
        return state.cumulatedPaymentTotal;
    },

    // Get the maximum number of records that will be returned
    getRecordLimit(state) {
        return state.recordLimit;
    },

    // Get the list's page limit value
    getPageLimit(state) {
        return state.pageLimit;
    },
};

const mutations = {

    // Reset back to original state
    resetPaymentData(state) {
        let newState = {...INITIAL_STATE };
        state = Object.assign(state, newState);
    },

    // Set the "From" Date
    setFromDate(state, date) {
        state.fromDate = date;
    },

    // Set the "To" Date
    setToDate(state, date) {
        state.toDate = date;
    },

    // Save the payment list
    setPayments(state, payments) {
        state.payments = payments;
    },

    // Set the selected payment
    setSelectedPayment(state, payment) {
        state.selectedPayment = payment;
    },

    // Get the selected payment
    setPaymentInvoices(state, paymentInvoices) {
        state.paymentInvoices = paymentInvoices;
    },

    // Set cumulated invoice totals
    setCumulatedInvoiceTotal(state, total) {
        state.cumulatedInvoiceTotal = total;
    },

    // Set cumulated payment totals
    setCumulatedPaymentTotal(state, total) {
        state.cumulatedPaymentTotal = total;
    },

    // Set the maximum number of records that will be returned
    setRecordLimit(state, limit) {
        state.recordLimit = limit;
    },

    // Save the list's page limit value
    setPageLimit(state, limit) {
        state.pageLimit = limit;
    },
};

const actions = {

    /**
     * Fetch payments for the selected customer
     *
     * @param dateRange in one of two forms:
     *      1. { fromDate: yyyy-mm-dd, toDate  yyyy-mm-dd}
     *      2. { fromDate: [Date Object], toDate  [Date Object]}
     * @return mixed true if successful, object of errors if not
     */
    async fetchPayments({ commit }, dateRange) {

        // If "from" date is a Date object, grab only the date portion of its ISO string version.
        if (typeof dateRange.fromDate == 'object') {
            dateRange.fromDate = dateRange.fromDate.toISOString().substring(0, 10);
        }

        // If "to" date is a Date object, grab only the date portion of its ISO string version.
        if (typeof dateRange.toDate == 'object') {
            dateRange.toDate = dateRange.toDate.toISOString().substring(0, 10);
        }

        // Fetch the payments from the API
        var response = await portalapi.modulePayments.getPayments(dateRange);
        if (!response.success) {
            let errors = { 'GLOBAL': 'Could not retrieve payments' };
            return (response.errors ? response.errors : errors);
        }

        // Update the payment list & set the maximum number of records that will be returned
        commit("setPayments", response.payments);
        commit("setCumulatedInvoiceTotal", response.cumulatedInvoiceTotal);
        commit("setCumulatedPaymentTotal", response.cumulatedPaymentTotal);
        commit("setRecordLimit", response.limit);

        return true;
    },
    /**
     * Fetch a single payment
     *
     * @param Object data which includes the follwoing
     *      1. { paymentId}
     *      2. { paymentDate}
     * @return mixed true if successful, object of errors if not
     */
    async fetchPayment({ commit }, data) {

        // If "from" date is a Date object, grab only the date portion of its ISO string version.
        if (typeof data.paymentDate == 'object') {
            data.paymentDate = data.paymentDate.toISOString().substring(0, 10);
        }

        let dateRange = {
            fromDate: data.paymentDate,
            toDate: data.paymentDate,
        };

        // Fetch the payments from the API for the date given
        var response = await portalapi.modulePayments.getPayments(dateRange);
        if (!response.success) {
            let errors = { 'GLOBAL': 'Could not retrieve payment' };
            return (response.errors ? response.errors : errors);
        }

        // filter the list of payment for the payment we are looking for
        let payment = response.payments.filter((param) => param.id === data.paymentId);

        if (payment.length == 0) {
            return { 'GLOBAL': 'Could not find payment' };
        }

        // return the found payment
        return payment[0];
    },

    /**
     * Fetch invoices for the selected payment
     *
     * @return mixed true if successful, object of errors if not
     */
    async fetchPaymentInvoices({ commit }, paymentId) {

        // Fetch the payments from the API
        var response = await portalapi.modulePayments.getPaymentInvoices(paymentId);
        if (!response.success) {
            let errors = { 'GLOBAL': 'Could not retrieve payments' };
            return (response.errors ? response.errors : errors);
        }

        commit("setPaymentInvoices", response.invoices);

        return true;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};