<template>
  <div>
    <router-view></router-view>
    <CSpinner v-if="showSpinner" color="info" class="spinner hidden" />
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    showSpinner() {
      // Show the login spinner only if loading and on the login page
      return this.$store.state.isLoading && vm.$route.name === "Login";
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

.spinner {
    top: 30%;
    left: 50%;
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-width: 0.65rem;
    opacity: 0.7;
    z-index: 9999;
}
</style>
