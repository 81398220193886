/**
 * Payment module for Suncast Portal
 * @module PaymentStore
 * @author Mithun Paulose (Fresche Solutions)
 * @date June 2020
 */

import { getField, updateField } from "vuex-map-fields";
import portalapi from "./portalapi";

const state = {
    paymentId: null,
    paymentDetails: null,
    paymentInfo: null,
};

const getters = {
    getPaymentId: (state) => {
        return state.paymentId;
    },
    getPaymentDetails: (state) => {
        return state.paymentDetails;
    },
    getPaymentInfo: (state) => {
        return state.paymentInfo;
    },

};

const mutations = {
    setPaymentId(state, payload) {
        state.paymentId = payload;
    },
    setPaymentDetails(state, payload) {
        state.paymentDetails = payload;
    },
    setPaymentInfo(state, payload) {
        state.paymentInfo = payload;
    },

};

const actions = {

    /**
     * set paymentId
     */
    async updatePaymentId({ commit }, data) {
        commit("setPaymentId", data);
    },
    /**
     * fetch Payment Details
     * list of invoices, amounts, subtotal, admin fee etc
     */
    async fetchPaymentDetails({ commit }) {
        var response = await portalapi.modulePayments.getPaymentDetails({id: state.paymentId});
        commit("setPaymentDetails", response.data);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
