/**
 * Browser check functions
 *
 * @module BrowserCheck
 * @author Kevin Jones (Fresche Solutions)
 * @date Oct 2020
 */


/**
 * Get the browser type
 *
 * @return mixed string or false if not found
 */
const getBrowserType = function() {

    // Check browser type by duck typing
    let browserType = getByDuckTyping();

    // Not found by duck typing - check the user agent string
    if (!browserType) {
        browserType = getByUserAgent();
    }

    return browserType;
};


/**
 * Check browser type by duck typing - IE: checking for features of the browser. This can become out of date so
 * should be used in conjunction with a user agent string check if none is found.
 *
 * Taken & modified from: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 * On October 10th, 2020
 *
 * @return mixed string or false if not found
 */
const getByDuckTyping = function() {

    var browserType = false;

    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera) {
        browserType = 'Opera';
    }

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
        browserType = 'Firefox';
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    if (isSafari) {
        browserType = 'Safari';
    }

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) {
        browserType = 'IE';
    }

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if (isEdge) {
        browserType = 'Edge';
    }

    // Chrome 1 - 79
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
        browserType = 'Chrome';
    }

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    if (isEdgeChromium) {
        browserType = 'EdgeChromium';
    }

    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;
    if (isBlink) {
        browserType = 'Blink';
    }

    return browserType;
}


/**
 * Check the browser's user agent string. This can be spoofed so we use it as a back-up to
 * duck typing.
 *
 * Taken & modified from: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 * On October 10th, 2020
 *
 * @return mixed string or false if not found
 */
const getByUserAgent = function() {

    var userAgent = navigator.userAgent;

    if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) != -1 )  {
        return 'Opera';
    }
    // Edge & Edge based on chromium
    if (userAgent.indexOf('Edg/') != -1 ) {
        return (userAgent.indexOf('Chrome') != -1 ? 'EdgeChromium' : 'Edge');
    }
    if (userAgent.indexOf('Chrome') != -1 ) {
        return 'Chrome';
    }
    if (userAgent.indexOf('Safari') != -1) {
        return 'Safari';
    }
    if (userAgent.indexOf('Firefox') != -1 ) {
        return 'Firefox';
    }
    // IF IE > 10
    if (userAgent.indexOf('MSIE') != -1 || (!!document.documentMode == true )) {
        return 'IE';
    }

    return false;
}



export default {
    namespaced: "true",
    getBrowserType,
};

